<template>
  <div class="app-container">
    <el-row class="margin-bottom-20" justify="center">
      <el-col :lg="18" :md="16" :sm="16" :xs="12">
        <el-input prefix-icon="el-icon-search" size="mini" placeholder="ID de transacción, ID familia, Correo" v-model="searchForm.query" v-on:change="handleSearchFilter()" clearable></el-input>
      </el-col>
      <el-col style="display: flex; justify-content: flex-start;" :md="4" :sm="8" :xs="10">
        <el-button
          @click.stop.prevent="handleSearchFilter"
          class="margin-left-10"
          size="mini"
          type="primary"
          icon="el-icon-search"
        >Buscar</el-button>
        <el-button
          class="margin-left-10"
          @click.stop.prevent="handleClean"
          size="mini"
          type="warning"
          icon="el-icon-delete"
        >Limpiar Filtros</el-button>
        <el-button
          @click.stop.prevent="handleExport"
          type="success"
          icon="el-icon-bottom"
          size="mini"
        >Exportar</el-button>
      </el-col>
      <!-- <el-col :lg="2" :xs="8" :offset="0">
        <el-button
          class="margin-left-10"
          @click.stop.prevent="handleExport"
          size="mini"
          type="success"
          icon="el-icon-bottom"
        >Exportar</el-button>
      </el-col> -->
    </el-row>
    <el-row justify="center">
      <el-col style="display: flex; justify-content: center;" :lg="4" :md="4" :sm="8" :xs="12">
          <el-select v-model="searchForm.location" clearable v-on:change="handleSearchFilter()" size="large" placeholder="Sucursal">
            <el-option
              v-for="location in locations"
              :key="location.location"
              :label="location.location"
              :value="location.location">
            </el-option>
          </el-select>
      </el-col>
      <el-col style="display: flex; justify-content: center;" :lg="4" :md="4" :sm="8" :xs="12">
          <el-select v-model="searchForm.type" clearable v-on:change="handleSearchFilter()" size="large" placeholder="Tipo de pago">
            <el-option
              v-for="tipo in types"
              :key="tipo.type"
              :label="tipo.type"
              :value="tipo.type">
            </el-option>
          </el-select>
      </el-col>
      <el-col style="display: flex; justify-content: center;" :lg="16" :md="16" :sm="10" :xs="24">
        <div class="grid-content bg-purple">
          <span class=" margin-filter">Registros por fecha</span>
          <el-date-picker
            v-model="filterDateRegisterRange"
            v-on:change="handleFilterDateRegister()"
            size="mini"
            type="daterange"
            align="right"
            unlink-panels
            format="dd-MM-yyyy"
            range-separator="a"
            start-placeholder="Fecha inicial"
            end-placeholder="Fecha final"
            :picker-options="pickerOptions">
          </el-date-picker>
        </div>
      </el-col>
    </el-row>
      <el-row justify="center">
        <el-col style="display: flex; justify-content: center;" :lg="4" :md="4" :sm="8" :xs="12">
            <el-select v-model="searchForm.status" clearable v-on:change="handleSearchFilter()" size="large" placeholder="Estatus">
              <el-option
                v-for="status in allStatus"
                :key="status.status"
                :label="status.status"
                :value="status.status">
              </el-option>
            </el-select>
        </el-col>
      </el-row>
    <el-row justify="center">
      <el-col :lg="24" :md="24" :sm="24" :xs="24" style="display: flex; justify-content: flex-end">
        <el-row type="flex" justify="end">
          <el-pagination @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
            :total="parseInt(totalPages)">
          </el-pagination>
        </el-row>
      </el-col>
    </el-row>
    <el-table
      size="mini"
      v-loading="loading"
      :data="list"
      :header-cell-class-name="tableRowClassName"
      element-loading-text="Loading"
      height="600"
      fit
      border
      highlight-current-row
      style="width: 100%"
    >
    <el-table-column label="ID Familia JR" width="130" align="center" fixed ="left">
      <template slot-scope="scope">
          <span>{{ scope.row.idFamilyJR }}</span>
      </template>
    </el-table-column>
    <el-table-column label="Familia" align="center">
      <el-table-column label="Nombre de la familia" width="250" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.familyName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Sucursal" width="120" align="center">
        <template slot-scope="scope">
            <span>{{ scope.row.location }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Correo electrónico" width="200" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.email }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Teléfono" width="150" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.phone }}</span>
        </template>
      </el-table-column>
    </el-table-column>
    <el-table-column label="Pago" align="center"  >
      <el-table-column label="Tipo de pago" width="150" align="center" >
        <template slot-scope="scope" >
          <span>{{ scope.row.type }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Estatus" width="100" align="center">
        <template slot-scope="scope">
          <el-tag
            size="small"
            :type="getStatusTagType(scope.row.status.toLowerCase())"
          >
            <span>{{ validateStatus(scope.row.status) }}</span>
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Monto" width="170" align="center">
        <template slot-scope="scope">
            <span>{{ scope.row.amount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Motivo de pago" width="150" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.paymentType }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Fecha de pago" width="150" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.paymentDateCreated | formatDateTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Conceptos" type="expand" width="110">
        <template slot-scope="scope">
          <!-- Tabla conceptos -->
          <el-table
            v-if="scope.row.concepts"
            :data="scope.row.concepts"
            size="mini"
            border
            highlight-current-row>
            <el-table-column label="ID Transaccion JR" width="130">
              <template slot-scope="scope">
                <span>{{ scope.row.idTransactionJR }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Tipo" width="150">
              <template slot-scope="scope">
                <span>{{ scope.row.type }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Subtipo" width="150">
              <template slot-scope="scope">
                <span>{{ scope.row.subType }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Monto original" width="120">
              <template slot-scope="scope">
                <span>{{ scope.row.originAmount }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Descuento" width="100">
              <template slot-scope="scope">
                <span>{{ scope.row.discountAmount }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Monto final" width="100">
              <template slot-scope="scope">
                <span>{{ scope.row.amount }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Monto a pagar" width="120">
              <template slot-scope="scope">
                <span>{{ scope.row.unpaidAmount }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Balance" width="100">
              <template slot-scope="scope">
                <span>{{ scope.row.balance }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Estudiante" width="300">
              <template slot-scope="scope">
                <span>{{ scope.row.studentName }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Nota" width="300">
              <template slot-scope="scope">
                <span>{{ scope.row.note }}</span>
              </template>
            </el-table-column>
          </el-table>
          <!-- Fin tabla conceptos -->
        </template>
      </el-table-column>
    </el-table-column>
    <el-table-column label="Fecha de creación" width="150" align="center" >
      <template slot-scope="scope">
        <span>{{ scope.row.created_at | formatDateTime }}</span>
      </template>
    </el-table-column>
    <el-table-column label="Fecha de actualización" width="160" align="center">
      <template slot-scope="scope">
        <span>{{ scope.row.updated_at | formatDateTime }}</span>
      </template>
    </el-table-column>
    <el-table-column label="Errores" width="350" align="center" >
      <template slot-scope="scope">
        <ul>
          <li v-for="error in scope.row.errors" :key="error">{{error}}</li>
        </ul>
      </template>
    </el-table-column>
    <el-table-column label="Operaciones" width="150" align="center" fixed ="right">
      <template slot-scope="scope">
        <el-button v-if="scope.row.status == 'error'" @click="handleDispatch(scope.row._id)" type="primary" size="mini">
            <span>Añadir</span>
          </el-button>
      </template>
    </el-table-column>
    </el-table>
    <el-row type="flex" justify="end">
    <el-pagination @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
      :total="parseInt(totalPages)">
    </el-pagination>
  </el-row>
  </div>
</template>

<script>
import { search, getPage, exportExcel, filterData, dispatch } from '@/api/paymentsStore'

export default {
  data () {
    return {
      searchForm: {
        query: '',
        initDate: '',
        endDate: '',
        paymentData: null,
        type: null
      },
      myStyle: {
        backgroundColor: '#16a085'
      },
      filterDateRegisterRange: '',
      pickerOptions: {
        shortcuts: [{
          text: 'Semana pasada',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Mes pasado',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '3 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '6 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '9 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 270)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '1 Año atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      totalPages: '',
      currentPage: '',
      list: null,
      loading: false,
      sizePerPage: 25,
      locations: null,
      types: null,
      allStatus: null
    }
  },
  mounted () {
    this.getQueryPayments()
    this.getQueryPayment()
    this.handleSearchFilter()
    this.handleGetLocations()
    this.handleGetTypes()
    this.handleGetStatus()
  },
  methods: {
    handleClean () {
      this.filterDateRegisterRange = []
      this.searchForm = {
        query: '',
        location: '',
        type: '',
        initDate: '',
        endDate: ''
      }
      this.handleSearchFilter()
    },
    async handleCurrentChange (page) {
      this.currentPage = page
      this.loading = true
      await getPage(page, this.sizePerPage, this.searchForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleChangeItemPage (val) {
      this.loading = true
      this.sizePerPage = val
      await getPage(this.currentPage, val, this.searchForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleSearchFilter () {
      this.loading = true
      await search(this.searchForm)
        .then((response) => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    handleFilterDateRegister () {
      if (this.filterDateRegisterRange) {
        this.searchForm.initDate = this.filterDateRegisterRange[0]
        this.searchForm.endDate = this.filterDateRegisterRange[1]
        this.handleSearchFilter()
      } else {
        this.searchForm.initDate = ''
        this.searchForm.endDate = ''
        this.handleSearchFilter()
      }
    },
    async handleExport () {
      this.loading = true
      await exportExcel(
        this.searchForm
      ).then(response => {
        const url = window.URL.createObjectURL(new Blob([response]))
        const link = document.createElement('a')
        link.href = url
        const name = 'Reporte-de-pagos-' + new Date() + '.xlsx'
        link.setAttribute('download', name)
        document.body.appendChild(link)
        link.click()
        console.log('Archivo Exportado')
      })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    getStatusTagType (status) {
      switch (status) {
        case 'initialized':
          return 'primary'
        case 'processing':
          return 'warning'
        case 'queued':
          return 'warning'
        case 'completed':
          return 'success'
        case 'error':
          return 'danger'
        default:
          return 'info'
      }
    },
    validateStatus (section) {
      switch (section) {
        case 'initialized':
          return 'Inicializado'
        case 'processing':
          return 'Procesando'
        case 'completed':
          return 'Completado'
        case 'error':
          return 'Error'
        case 'unprocessable':
          return 'Improsesable'
        case 'queued':
          return 'En cola'
        default:
          return 'N/D'
      }
    },
    async handleDispatch (id) {
      this.loading = true
      await dispatch(id)
        .then((response) => {
          console.log(response)
          this.handleSearchFilter()
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleGetLocations () {
      this.loading = true
      await filterData({ fieldSearch: 'location' })
        .then((response) => {
          this.locations = response.data
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleGetTypes () {
      this.loading = true
      await filterData({ fieldSearch: 'type' })
        .then((response) => {
          this.types = response.data
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleGetStatus () {
      this.loading = true
      await filterData({ fieldSearch: 'status' })
        .then((response) => {
          this.allStatus = response.data
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    getQueryPayments () {
      if (this.$route.query.idGroupTransaction !== undefined && this.$route.query.idGroupTransaction !== null) {
        this.searchForm.idGroupTransaction = this.$route.query.idGroupTransaction
      }
    },
    getQueryPayment () {
      if (this.$route.query.id !== undefined && this.$route.query.id !== null) {
        this.searchForm.paymentData = this.$route.query.id
      }
    },
    tableRowClassName ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 2 && column.children) {
        // Aplica el estilo a la cabecera de la subtabla interna
        return 'payment-column'
      }
      if ((columnIndex === 4 || columnIndex === 5 || columnIndex === 6 || columnIndex === 7 || columnIndex === 8 || columnIndex === 9) && rowIndex === 1) {
        // Aplica el estilo a la cabecera de la subtabla interna
        return 'payment-column'
      }
    },
    tableRowClassNameSecond ({ row, column, rowIndex, columnIndex }) {
      return 'payment-column'
    }
  }
}
</script>

<style >
/* ... tu estilo existente ... */
.el-table .payment-column {
  background: #e4e7ed !important; /* Color de fondo amarillo como ejemplo, cámbialo según tus preferencias */
}
</style>
