import request from '@/utils/request'

export function search (params) {
  return request({
    url: '/payment/automation/search',
    method: 'post',
    params
  })
}

export function getPage (page, noitems, params) {
  return request({
    url: '/payment/automation/search?page=' + page + '&item=' + noitems,
    method: 'post',
    params
  })
}

export function dispatch (id) {
  return request({
    url: '/payment/automation/dispatch/' + id,
    method: 'get'
  })
}

export function exportExcel (data) {
  return request({
    url: '/payment/automation/store/exportExcel',
    method: 'post',
    responseType: 'blob',
    data
  })
}

export function filterData (params) {
  return request({
    url: '/payment/automation/filterData',
    method: 'post',
    params
  })
}
